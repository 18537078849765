import React, {useEffect, useState} from 'react';
import {
    Alert,
    Button,
    Container, Divider,
    FormControl, FormControlLabel, FormGroup, FormLabel,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select, Snackbar, Switch,
    TextField
} from "@mui/material";
import {useSelector} from "react-redux";
import {selectUser} from "../redux/userSlice";

const Expenses = () => {

    const user = useSelector(selectUser);
    const token = localStorage.getItem('token');

    const [values, setValues] = useState({
        account: 2,
        accountTo: '',
        paymentType: 'expense',
        category: '',
        amount: 0,
        amountTo: 0,
        description: '',
        CAE: '',
        emissionDate: new Date().toISOString().slice(0, 10),
        taxes: [
            {
                type: '',
                province: null,
                amount: 0,
            },
        ],
        file: null,
    });

    const [accounts, setAccounts] = useState([]);
    const [provinces, setProvinces] = useState([]);
    const [categories, setCategories] = useState([]);

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        });
    }

    const fetchAccounts = async () => {
        const response = await fetch('https://internal-api.flashpass.com.ar/accounts.php', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'same-origin',
                'Authorization': 'Bearer ' + user.token,
            }
        });
        const json = await response.json();

        setAccounts(json.accounts);

        const response2 = await fetch('https://internal-api.flashpass.com.ar/provinces.php', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'same-origin',
                'Authorization': 'Bearer ' + user.token,
            }
        });
        const json2 = await response2.json();

        setProvinces(json2.provinces);

        const response3 = await fetch('https://internal-api.flashpass.com.ar/transaction-categories.php', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'same-origin',
                'Authorization': 'Bearer ' + token,
            }
        });
        const json3 = await response3.json();

        setCategories(json3.categories);
    }

    const submitData = async () => {

        const formData = new FormData();
        formData.append('file', values.file);
        formData.append('data', JSON.stringify(values));


        const request = await fetch('https://internal-api.flashpass.com.ar/transactions.php', {
            method: 'POST',
            headers: {
                'credentials': 'same-origin',
                'Authorization': 'Bearer ' + user.token,
            },
            body: formData
        });

        const response = await request.json();

        if (response.status === 'success') {
            setSuccessMessage(response.message);
            setOpenS(true);
        } else {
            setErrorMessage(response.message);
            setOpenE(true);
        }
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        console.log(values);
        submitData();
    }

    const [checked, setChecked] = React.useState(true);

    const [openS, setOpenS] = useState(false);
    const [openE, setOpenE] = useState(false);

    const handleCloseS = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenS(false);
    };

    const handleCloseE = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenE(false);
    };

    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleCheck = (event) => {
        setChecked(event.target.checked);
    };

    useEffect(() => {
        fetchAccounts();
    }, []);

    useEffect(() => {
        console.log(values);
    }, [values]);

    return (
        <div>
            <Snackbar open={openS} autoHideDuration={6000} onClose={handleCloseS}>
                <Alert onClose={handleCloseS} severity="success" sx={{ width: '100%' }}>
                    {successMessage}
                </Alert>
            </Snackbar>
            <Snackbar open={openE} autoHideDuration={6000} onClose={handleCloseE}>
                <Alert onClose={handleCloseE} severity="error" sx={{ width: '100%' }}>
                    {errorMessage}
                </Alert>
            </Snackbar>

            <Container>
                <div className="row">
                    <div className="col-12">
                        <h1 className="text-2xl font-bold mb-2">Nueva transacción</h1>
                    </div>
                </div>

                <form onSubmit={handleSubmit}>
                    <div className="row mt-3">
                        <div className="col-lg-6">
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Tipo de transacción</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={values.paymentType}
                                    label="Tipo de transacción"
                                    onChange={handleChange}
                                    name={'paymentType'}
                                >
                                    <MenuItem value={"income"}>Ingreso</MenuItem>
                                    <MenuItem value={"expense"}>Gasto</MenuItem>
                                    <MenuItem value={"transfer"}>Transferencia</MenuItem>
                                    {/*<MenuItem value={"equity_draw"}>Retiro Socios</MenuItem>*/}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-lg-6">
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">{values.paymentType === 'transfer' ? "Cuenta Origen" : "Cuenta"}</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={values.account}
                                    label={values.paymentType === 'transfer' ? "Cuenta Origen" : "Cuenta"}
                                    onChange={handleChange}
                                    name={'account'}
                                >
                                    {accounts.map((account) => (
                                        <MenuItem key={account.id} value={account.id}>{account.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    {values.paymentType === 'transfer' && (
                        <div className="row mt-3">
                            <div className="col-lg-6">
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Cuenta Destino</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={values.accountTo}
                                        label="Cuenta Destino"
                                        onChange={handleChange}
                                        name={'accountTo'}
                                    >
                                        {accounts.map((account) => (
                                            <MenuItem key={account.id} value={account.id}>{account.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    )}
                    <div className="row mt-3">
                        <div className="col-lg-6">
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Categoría</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={values.category}
                                    label="Categoría"
                                    onChange={handleChange}
                                    name={'category'}
                                >
                                    {categories.filter(category => category.type === values.paymentType).map((category) => (
                                        <MenuItem key={category.id} value={category.id}>{category.group_name ? <b>{category.group_name} - </b> : null}{category.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-lg-6">
                            <TextField label="Descripción" value={values.description} rows={4} multiline onChange={handleChange} className="mb-3" name={"description"} fullWidth variant="outlined" />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-lg-6">
                            <FormControl fullWidth>
                                <InputLabel htmlFor="outlined-adornment-amount">{values.paymentType === 'transfer' ? "Monto Bruto Cta Destino" : "Monto Bruto"}</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-amount"
                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                    label={values.paymentType === 'transfer' ? "Monto Bruto Cta Destino" : "Monto Bruto"}
                                    onChange={handleChange}
                                    name={'amount'}
                                    type={"number"}
                                    value={values.amount}
                                />
                            </FormControl>
                        </div>
                    </div>
                    {values.paymentType === 'transfer' && (
                        <div className="row mt-3">
                            <div className="col-lg-6">
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="outlined-adornment-amount">Monto Bruto Cta Destino</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-amount"
                                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                        label="Monto Cta Destino"
                                        onChange={handleChange}
                                        name={'amountTo'}
                                        type={"number"}
                                        value={values.amountTo}
                                    />
                                </FormControl>
                            </div>
                        </div>
                    )}
                    <div className="row mt-3">
                        <div className="col-lg-6">
                            <TextField label="Fecha de emisión" value={values.emissionDate} onChange={handleChange}
                                       className="mb-3" name={"emissionDate"} fullWidth variant="outlined"
                                       type={"date"}/>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-lg-6">
                            <FormControl component="fieldset" variant="standard">
                                <FormLabel component="legend">Datos de la factura</FormLabel>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Switch checked={checked} onChange={handleCheck} name="gilad" />
                                        }
                                        label="Factura"
                                    />
                                </FormGroup>
                            </FormControl>
                        </div>
                    </div>
                    {checked && (
                        <>
                            <div className="row mt-3">
                                <div className="col-lg-6">
                                    <TextField label="CAE" value={values.CAE} onChange={handleChange} className="mb-3" name={"CAE"} fullWidth variant="outlined" />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-lg-6">
                                    <Button
                                        variant="text"
                                        color="primary"
                                        onClick={() => {
                                            const newTaxes = [...values.taxes];
                                            newTaxes.push({
                                                type: '',
                                                province: null,
                                                amount: 0,
                                            });
                                            setValues({ ...values, taxes: newTaxes });
                                        }}
                                    >
                                        + Agregar Impuesto
                                    </Button>
                                </div>
                            </div>
                            {values.taxes.map((tax, index) => (
                                <div key={index} className="row">
                                    <div className="col-lg-6">
                                        <div className="row mt-3">
                                            <div className="col">
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">Tipo de Impuesto</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={values.taxes[index].type}
                                                        label="Tipo de Impuesto"
                                                        onChange={
                                                            (event) => {
                                                                const newTaxes = [...values.taxes];
                                                                newTaxes[index].type = event.target.value;
                                                                setValues({ ...values, taxes: newTaxes });
                                                            }
                                                        }
                                                        name={'account'}
                                                    >
                                                        <MenuItem value={"iva"}>IVA</MenuItem>
                                                        <MenuItem value={"iibb"}>IIBB</MenuItem>
                                                        <MenuItem value={"ganancias"}>Ganancias</MenuItem>
                                                        <MenuItem value={"iidc"}>Débitos/Créditos</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>
                                        {tax.type === 'iibb' && (
                                            <div className="row mt-3">
                                                <div className="col">
                                                    <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label">Provincia</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={values.taxes[index].province}
                                                            label="Provincia"
                                                            onChange={
                                                                (event) => {
                                                                    const newTaxes = [...values.taxes];
                                                                    newTaxes[index].province = event.target.value;
                                                                    setValues({ ...values, taxes: newTaxes });
                                                                }
                                                            }
                                                            name={'account'}
                                                        >
                                                            {provinces.map((province) => (
                                                                <MenuItem key={province.province_id} value={province.province_id}>{province.province_name}</MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        )}
                                        <div className="row mt-3">
                                            <div className="col">
                                                <FormControl fullWidth>
                                                    <InputLabel htmlFor="outlined-adornment-amount">Monto</InputLabel>
                                                    <OutlinedInput
                                                        id="outlined-adornment-amount"
                                                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                        label="Monto"
                                                        onChange={
                                                            (event) => {
                                                                const newTaxes = [...values.taxes];
                                                                newTaxes[index].amount = event.target.value;
                                                                setValues({ ...values, taxes: newTaxes });
                                                            }
                                                        }
                                                        name={'amount'}
                                                        type={"number"}
                                                        value={values.taxes[index].amount}
                                                    />
                                                </FormControl>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row text-center">
                                        <div className="col-lg-6">
                                            <Button className={"mt-2"} variant="text" color="error" onClick={() => {
                                                const newTaxes = [...values.taxes];
                                                newTaxes.splice(index, 1);
                                                setValues({ ...values, taxes: newTaxes });
                                            }}>
                                                Eliminar
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <div className="row mt-4">
                                <div className="col-lg-6">
                                    <div className="row">
                                        <div className="col">
                                            <h5>Subir comprobante</h5>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <input
                                                accept={"image/*, application/pdf"}
                                                type="file"
                                                name={"file"}
                                                onChange={(image) => setValues({...values, file: image
                                                        .target.files[0]})}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}

                    <div className="row mt-5 mb-5">
                        <div className="col-lg-6">
                            <Button variant="contained" color="primary" type={"submit"} fullWidth>
                                Emitir Comprobante
                            </Button>
                        </div>
                    </div>
                </form>
            </Container>
        </div>
    );
};

export default Expenses;