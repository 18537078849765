import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '../redux/userSlice';
import { number_format } from './functions/numberFormat';
import DateFilter from './DateFilter';

const ProfitAndLoss = () => {
    const user = useSelector(selectUser);

    const [data, setData] = useState([]);
    const [dateRange, setDateRange] = useState({ fromDate: '', toDate: '' });

    // Función para fetch de datos
    const fetchData = async () => {
        const request = await fetch(
            `https://internal-api.flashpass.com.ar/profit-and-loss-statement.php?date_from=${dateRange.fromDate}&date_to=${dateRange.toDate}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    credentials: 'same-origin',
                    Authorization: 'Bearer ' + user.token,
                },
            }
        );

        const response = await request.json();
        setData(response);
    };

    // Manejo del cambio de rango de fechas
    const handleDateRangeChange = useCallback((newDateRange) => {
        setDateRange(newDateRange);
    }, []);

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.token, dateRange]);

    return (
        <div className="max-w-4xl mx-auto p-4">
            {/* Título */}
            <h1 className="text-2xl font-bold mb-4">P&L Statement</h1>

            {/* DateFilter (usa MUI internamente para el date picker) */}
            <div className="mt-3 mb-3">
                <DateFilter
                    fromDate={dateRange.fromDate}
                    toDate={dateRange.toDate}
                    onDateRangeChange={handleDateRangeChange}
                />
            </div>

            {/* Income */}
            <div className="mt-5">
                <div className="flex items-center justify-between">
                    <h3 className="text-lg font-semibold">Income</h3>
                    <b>
                        $ {number_format(data?.ARS?.total_income, 0, ',', '.')} (
                        {number_format(data?.USD?.total_income, 0, ',', '.')} USD)
                    </b>
                </div>

                {data?.ARS?.income?.map((item, index) => (
                    <div key={index} className="flex items-center justify-between mt-2">
                        <p className="mb-0">{item.name}</p>
                        <p className="mb-0">
                            $ {number_format(item.amount, 0, ',', '.')} (
                            {number_format(data?.USD?.income[index], 0, ',', '.')} USD)
                        </p>
                    </div>
                ))}

                <hr className="my-4 border-gray-300" />

                {/* Expenses */}
                <div className="flex items-center justify-between">
                    <h3 className="text-lg font-semibold">Expenses</h3>
                    <b>
                        $ {number_format(data?.ARS?.total_expenses, 0, ',', '.')} (
                        {number_format(data?.USD?.total_expenses, 0, ',', '.')} USD)
                    </b>
                </div>

                {data?.ARS?.expenses?.map((item, index) => (
                    <div key={index} className="flex items-center justify-between mt-2">
                        <p className="mb-0">{item.name}</p>
                        <p className="mb-0">
                            $ {number_format(item.amount, 0, ',', '.')} (
                            {number_format(data?.USD?.expenses[index], 0, ',', '.')} USD)
                        </p>
                    </div>
                ))}

                <hr className="my-4 border-gray-300" />

                {/* Profit */}
                <div className="flex items-center justify-between mt-4">
                    <h3 className="text-lg font-semibold">Profit</h3>
                    <b>
                        $ {number_format(data?.ARS?.total_income + data?.ARS?.total_expenses, 0, ',', '.')} (
                        {number_format(
                            data?.USD?.total_income + data?.USD?.total_expenses,
                            0,
                            ',',
                            '.'
                        )}{' '}
                        USD)
                    </b>
                </div>
            </div>
        </div>
    );
};

export default ProfitAndLoss;
