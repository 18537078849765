import React, { useEffect } from 'react';
import { Container } from '@mui/material';
import { useNavigate } from 'react-router';

const Home = () => {
    const token = window.localStorage.getItem('token');
    const navigate = useNavigate();

    const [showMenu, setShowMenu] = React.useState(false);
    const [options, setOptions] = React.useState([]);

    const fetchData = async () => {
        const request = await fetch('https://internal-api.flashpass.com.ar/user-permissions', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                credentials: 'same-origin',
                Authorization: 'Bearer ' + token,
            },
        });

        const data = await request.json();

        if (data.status === 'success') {
            setOptions(data.data);
        }
    };

    const validateToken = async () => {
        const request = await fetch('https://internal-api.flashpass.com.ar/check-jwt.php', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                credentials: 'same-origin',
                Authorization: 'Bearer ' + token,
            },
        });

        try {
            const response = await request;
            let json = null;

            try {
                json = await response.json();
            } catch (error) {
                // Manejo del error si la respuesta no es JSON
            }

            if (json?.status === 'error' || response.status === 401) {
                window.localStorage.removeItem('token');
                navigate('/login');
            } else {
                setShowMenu(true);
            }
        } catch (error) {
            // Manejo de un error de red u otro
        }
    };

    useEffect(() => {
        if (!token) {
            navigate('/login');
        }

        validateToken();
        fetchData();
    }, [token]);

    return (
        <div className="bg-gray-50 min-h-screen">
            <Container className="py-10">
                <h1 className="text-2xl font-bold mb-6">Inicio</h1>

                {showMenu && (
                    <div className="space-y-4">
                        <ul className="list-disc list-inside space-y-2 list-none">
                            {options.map((option, index) => (
                                <li key={index}>
                                    <a
                                        href={option.name}
                                        className="underline"
                                    >
                                        {option.description}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </Container>
        </div>
    );
};

export default Home;
