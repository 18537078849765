import React, { useEffect, useState } from 'react';

const EventList = () => {
    const [events, setEvents] = useState([]);
    const token = localStorage.getItem('token');

    const fetchData = async () => {
        const request = await fetch('https://internal-api.flashpass.com.ar/event-approve', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                credentials: 'same-origin',
                Authorization: 'Bearer ' + token,
            },
        });

        const response = await request.json();
        if (response.status === 'success') {
            setEvents(response.data);
        }
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="max-w-5xl mx-auto px-4 py-6">
            {/* Encabezado */}
            <div className="text-center mb-6">
                <h1 className="text-2xl font-bold">Eventos Futuros</h1>
            </div>

            {/* Contenedor de la tabla con scroll si excede 600px de alto */}
            <div className="overflow-auto max-h-[600px]">
                <table className="min-w-full border-collapse text-left">
                    <thead className="border-b">
                    <tr>
                        <th className="py-2 px-4 font-semibold">Portada</th>
                        <th className="py-2 px-4 font-semibold">Evento</th>
                        <th className="py-2 px-4 font-semibold">Acciones</th>
                    </tr>
                    </thead>
                    <tbody>
                    {events.map((event, index) => (
                        <tr
                            key={index}
                            className="hover:bg-gray-50 transition-colors duration-200"
                        >
                            <td className="p-4 align-middle">
                                <img
                                    src={event.portadaa}
                                    alt="Portada"
                                    className="w-24 h-24 object-cover rounded"
                                />
                            </td>
                            <td className="p-4 align-middle">{event.event_name}</td>
                            <td className="p-4 align-middle">
                                <a
                                    href={`/event/${event.event_id}`}
                                    className="inline-block bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
                                >
                                    Ver Evento
                                </a>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default EventList;
