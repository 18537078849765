import React, { useEffect, useState } from 'react';
import {
    Alert,
    Button,
    Container,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Snackbar,
    Switch,
    TextField
} from "@mui/material";
import { selectUser } from "../redux/userSlice";
import { useSelector } from "react-redux";

// Importa SweetAlert2
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';

const EventPayment = () => {
    const user = useSelector(selectUser);

    const [disabledConfirm, setDisabledConfirm] = useState(false);

    // Si quieres seguir usando los Snackbars, déjalos
    const [openS, setOpenS] = useState(false);
    const [openE, setOpenE] = useState(false);

    const handleCloseS = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenS(false);
    };

    const handleCloseE = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenE(false);
    };

    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const [values, setValues] = useState({
        event: '',
        paymentType: '',
        amount: '',
        amountInput: '',
        bankCBU: '',
        bankCUIT: '',
        bankCUITFormatted: '',
        bankName: '',
        fiscalType: '',
        flgMartin: false,
        noFiscalReciept: false,
    });

    const [amountError, setAmountError] = useState('');
    const [events, setEvents] = useState([]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setValues({
            ...values,
            [name]: value,
        });
    };

    const fetchEvents = async () => {
        const response = await fetch('https://internal-api.flashpass.com.ar/events.php', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'same-origin',
                'Authorization': 'Bearer ' + user.token,
            }
        });
        const json = await response.json();
        setEvents(json.data);
    };

    const submitData = async (valuesToSubmit) => {
        // Mostramos el loader de SweetAlert
        Swal.fire({
            title: 'Cargando...',
            text: 'Estamos procesando tu pago',
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });

        setDisabledConfirm(true);

        try {
            const request = await fetch('https://internal-api.flashpass.com.ar/pago-evento.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'credentials': 'same-origin',
                    'Authorization': 'Bearer ' + user.token,
                },
                body: JSON.stringify(valuesToSubmit),
            });

            const response = await request.json();

            // Cerramos el loader
            Swal.close();

            if (response.communicationMsg === "Success") {
                // SweetAlert de Éxito
                Swal.fire({
                    icon: 'success',
                    title: '¡Pago realizado con éxito!',
                    text: 'Tu pago fue registrado correctamente',
                    confirmButtonText: 'OK'
                });
                setSuccessMessage('Pago realizado con exito');
            } else {
                // SweetAlert de Error
                Swal.fire({
                    icon: 'error',
                    title: 'Error al realizar el pago',
                    text: 'Ocurrió un problema al registrar tu pago',
                    confirmButtonText: 'OK'
                });
                setErrorMessage('Error al realizar el pago');
            }
        } catch (error) {
            Swal.close();
            Swal.fire({
                icon: 'error',
                title: 'Error inesperado',
                text: 'No se pudo procesar tu solicitud',
                confirmButtonText: 'OK'
            });
            setErrorMessage('Error al realizar el pago');
        } finally {
            setDisabledConfirm(false);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // Trim del bankName antes de mandar
        const valuesToSubmit = {
            ...values,
            bankName: values.bankName.trim(),
        };
        submitData(valuesToSubmit);
    };

    useEffect(() => {
        fetchEvents();
    }, []);

    // Custom handler para el monto
    const handleAmountChange = (event) => {
        let input = event.target.value;

        // Remover simbolo $ y espacios
        input = input.replace(/[$\s]/g, '');

        // Removemos esos mismos caracteres del display
        event.target.value = input;

        // Solo permitir dígitos, comas, puntos y guión
        const validInputPattern = /^[\d.,-]*$/;

        if (!validInputPattern.test(input)) {
            setAmountError('Caracteres inválidos en el monto.');
            setDisabledConfirm(true);
            setValues({
                ...values,
                amountInput: event.target.value,
                amount: '',
            });
            return;
        } else {
            setAmountError('');
        }

        let amount;

        if (input.includes('.') && input.includes(',')) {
            // Formato argentino
            input = input.replace(/\./g, ''); // Quitamos separadores de miles
            input = input.replace(',', '.');  // Coma decimal -> punto
        } else if (input.includes(',')) {
            // Coma como decimal
            input = input.replace(/\./g, '');
            input = input.replace(',', '.');
        }
        amount = parseFloat(input);

        if (isNaN(amount)) {
            setAmountError('El monto ingresado no es válido.');
            setDisabledConfirm(true);
        } else {
            setAmountError('');
            setDisabledConfirm(false);
        }

        setValues({
            ...values,
            amount: isNaN(amount) ? '' : amount,
            amountInput: event.target.value,
        });
    };

    // Custom handler para CUIT
    const handleCUITChange = (event) => {
        let input = event.target.value;

        // Solo dígitos
        input = input.replace(/\D/g, '');
        // Solo 11 dígitos
        input = input.substring(0, 11);

        // Formatear como nn-nnnnnnnn-n
        let formattedInput = '';
        if (input.length >= 2) {
            formattedInput += input.substring(0, 2) + '-';
        } else {
            formattedInput += input;
        }
        if (input.length >= 10) {
            formattedInput += input.substring(2, 10) + '-';
            formattedInput += input.substring(10);
        } else if (input.length > 2) {
            formattedInput += input.substring(2);
        }

        setValues({
            ...values,
            bankCUIT: input, // Valor sin formato
            bankCUITFormatted: formattedInput, // Valor formateado
        });
    };

    return (
        <div>
            {/* Si aún quieres usar los Snackbars de MUI */}
            <Snackbar open={openS} autoHideDuration={6000} onClose={handleCloseS}>
                <Alert onClose={handleCloseS} severity="success" sx={{ width: '100%' }}>
                    {successMessage}
                </Alert>
            </Snackbar>
            <Snackbar open={openE} autoHideDuration={6000} onClose={handleCloseE}>
                <Alert onClose={handleCloseE} severity="error" sx={{ width: '100%' }}>
                    {errorMessage}
                </Alert>
            </Snackbar>

            <Container>
                <div className="row">
                    <div className="col-12">
                        <h1 className="text-2xl font-bold mb-6">Event Payment</h1>
                    </div>
                </div>

                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-lg-6">
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Evento</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={values.event}
                                    label="Evento"
                                    onChange={handleChange}
                                    name={'event'}
                                >
                                    {events.map((event) => (
                                        <MenuItem
                                            key={event.event_id}
                                            value={event.event_id}
                                        >
                                            {event.event_id} - {event.event_name} - {event.event_date}
                                        </MenuItem>
                                    ))}
                                    {events.length === 0 && (
                                        <MenuItem disabled value={0}>
                                            No hay eventos disponibles
                                        </MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-lg-6">
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Tipo de pago</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={values.paymentType}
                                    label="Tipo de pago"
                                    onChange={handleChange}
                                    name={'paymentType'}
                                >
                                    <MenuItem value={"Adelanto"}>Adelanto</MenuItem>
                                    <MenuItem value={"Liquidacion Final"}>Liquidacion Final</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-lg-6">
                            <FormControl fullWidth error={!!amountError}>
                                <InputLabel htmlFor="outlined-adornment-amount">Monto</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-amount"
                                    label="Monto"
                                    onChange={handleAmountChange}
                                    name={'amount'}
                                    value={values.amountInput || ''}
                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                />
                                {amountError && (
                                    <p style={{ color: 'red', marginTop: '5px' }}>{amountError}</p>
                                )}
                            </FormControl>
                            <div>
                                <small>Monto a ingresar: {values.amount}</small>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-5">
                        <div className="col-lg-6">
                            <TextField
                                label="CBU"
                                value={values.bankCBU}
                                onChange={handleChange}
                                className="mb-3"
                                type="number"
                                fullWidth
                                variant="outlined"
                                name={'bankCBU'}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-6">
                            <TextField
                                label="CUIT"
                                value={values.bankCUITFormatted || ''}
                                onChange={handleCUITChange}
                                className="mb-3"
                                type="text"
                                fullWidth
                                variant="outlined"
                                name={'bankCUIT'}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-6">
                            <TextField
                                label="Nombre del Beneficiario"
                                value={values.bankName}
                                onChange={handleChange}
                                className="mb-3"
                                type="text"
                                fullWidth
                                variant="outlined"
                                name={'bankName'}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-6">
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Condición Fiscal</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={values.fiscalType}
                                    label="Condición Fiscal"
                                    onChange={handleChange}
                                    name={'fiscalType'}
                                >
                                    <MenuItem value={4}>Consumidor Final</MenuItem>
                                    <MenuItem value={5}>Responsable Monotributo</MenuItem>
                                    <MenuItem value={1}>Responsable Inscripto</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-lg-6">
                            <FormControl component="fieldset" variant="standard">
                                <FormLabel component="legend">Adicionales</FormLabel>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={values.flgMartin}
                                                onChange={(e) => setValues({ ...values, flgMartin: e.target.checked })}
                                                name="flgMartin"
                                            />
                                        }
                                        label="Pago a Martin Financiera"
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={values.noFiscalReciept}
                                                onChange={(e) => setValues({ ...values, noFiscalReciept: e.target.checked })}
                                                name="noFiscalReciept"
                                            />
                                        }
                                        label="NO Emitir Comprobante"
                                    />
                                </FormGroup>
                            </FormControl>
                        </div>
                    </div>

                    <div className="row mt-4">
                        <div className="col-lg-6">
                            <Button
                                variant="contained"
                                color="primary"
                                type={"submit"}
                                fullWidth
                                disabled={disabledConfirm || !!amountError}
                            >
                                Emitir Comprobante
                            </Button>
                        </div>
                    </div>
                </form>
            </Container>
        </div>
    );
};

export default EventPayment;
