import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '../redux/userSlice';

const TicketEditor = () => {
    const [ticketSearchTerm, setTicketSearchTerm] = useState('');
    const [selectedTicket, setSelectedTicket] = useState(null);
    const [searchResults, setSearchResults] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);

    // Estados para manejo de "toast" de éxito y error
    const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
    const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);

    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const user = useSelector(selectUser);

    // Handlers para cerrar las alertas de éxito/error
    const closeSuccessAlert = () => setOpenSuccessSnackbar(false);
    const closeErrorAlert = () => setOpenErrorSnackbar(false);

    // Búsqueda de tickets en la API
    const searchTickets = async (term) => {
        if (!term) return;
        try {
            const response = await fetch(
                `https://internal-api.flashpass.com.ar/ticket.php?search=${term}`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        credentials: 'same-origin',
                        Authorization: `Bearer ${user.token}`,
                    },
                }
            );
            const data = await response.json();
            if (data.status === 'success') {
                setSearchResults(data.data);
            }
        } catch (error) {
            console.error('Error buscando tickets:', error);
        }
    };

    // Actualización de ticket (PUT)
    const updateTicket = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        if (!selectedTicket) return;

        try {
            const response = await fetch('https://internal-api.flashpass.com.ar/ticket.php', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user.token}`,
                },
                body: JSON.stringify(selectedTicket),
            });
            const data = await response.json();

            if (data.status === 'success') {
                setSuccessMessage(data.message);
                setOpenSuccessSnackbar(true);
            } else {
                setErrorMessage(data.message);
                setOpenErrorSnackbar(true);
            }
        } catch (err) {
            setErrorMessage('Ocurrió un error al actualizar el ticket.');
            setOpenErrorSnackbar(true);
        } finally {
            setIsSubmitting(false);
        }
    };

    // Manejo de cambios en campos del ticket seleccionado
    const handleTicketFieldChange = (key, value) => {
        setSelectedTicket((prev) => ({
            ...prev,
            [key]: value,
        }));
    };

    // Efecto para la búsqueda con retardo
    useEffect(() => {
        if (!ticketSearchTerm) {
            setSearchResults([]);
            return;
        }

        const timeoutId = setTimeout(() => {
            searchTickets(ticketSearchTerm);
        }, 300);

        return () => clearTimeout(timeoutId);
    }, [ticketSearchTerm]);

    return (
        <div className="max-w-4xl mx-auto p-4">
            {/* Alertas de Éxito */}
            {openSuccessSnackbar && (
                <div className="fixed top-4 right-4 z-50">
                    <div className="flex items-start space-x-2 bg-green-100 border border-green-400 text-green-700 px-4 py-2 rounded shadow-md">
                        <strong className="font-bold">Éxito:</strong>
                        <span>{successMessage}</span>
                        <button
                            onClick={closeSuccessAlert}
                            className="ml-2 text-green-700 hover:text-green-900 font-semibold"
                        >
                            X
                        </button>
                    </div>
                </div>
            )}

            {/* Alertas de Error */}
            {openErrorSnackbar && (
                <div className="fixed top-4 right-4 z-50">
                    <div className="flex items-start space-x-2 bg-red-100 border border-red-400 text-red-700 px-4 py-2 rounded shadow-md">
                        <strong className="font-bold">Error:</strong>
                        <span>{errorMessage}</span>
                        <button
                            onClick={closeErrorAlert}
                            className="ml-2 text-red-700 hover:text-red-900 font-semibold"
                        >
                            X
                        </button>
                    </div>
                </div>
            )}

            {/* Título principal */}
            <h1 className="text-2xl font-bold mb-4">Modificar Ticket</h1>

            {/* Campo de búsqueda + lista de resultados */}
            <div className="mb-4">
                <label htmlFor="searchTicket" className="block text-gray-700 font-semibold mb-2">
                    Buscar Ticket
                </label>

                <div className="relative">
                    <input
                        id="searchTicket"
                        type="text"
                        className="block w-full border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-blue-500"
                        placeholder="Ej: 'John Doe - 12345'"
                        value={ticketSearchTerm}
                        onChange={(e) => setTicketSearchTerm(e.target.value)}
                    />

                    {/* Botón para limpiar el campo si hay texto */}
                    {ticketSearchTerm && (
                        <button
                            type="button"
                            className="absolute right-2 top-2 text-gray-500 hover:text-gray-700"
                            onClick={() => {
                                setTicketSearchTerm('');
                                setSearchResults([]);
                                setSelectedTicket(null);
                            }}
                        >
                            X
                        </button>
                    )}

                    {searchResults.length > 0 && (
                        <ul className="absolute top-full left-0 w-full bg-white border border-gray-300 rounded-md mt-1 shadow-lg max-h-60 overflow-y-auto z-10">
                            {searchResults.map((result) => (
                                <li
                                    key={result.ticket_id}
                                    className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                                    onClick={() => {
                                        setSelectedTicket(result);
                                        setSearchResults([]);
                                        setTicketSearchTerm(`${result.ticket_name} - ${result.ticket_id}`);
                                    }}
                                >
                                    {result.ticket_name} - {result.ticket_id}
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            </div>

            {/* Formulario para editar el ticket seleccionado */}
            {selectedTicket && (
                <form onSubmit={updateTicket} className="bg-white p-4 rounded shadow-md">
                    <h2 className="text-xl font-semibold mb-3">Información del Ticket</h2>

                    {/* Alerta si el ticket fue vendido en Menta */}
                    {selectedTicket?.menta_sold === 1 && (
                        <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-3 mb-3 rounded">
                            Este ticket fue <b>vendido en Menta</b>. No modifiques el nombre, DNI o email.
                        </div>
                    )}

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        {/* Ticket ID */}
                        <div>
                            <label className="block text-gray-700 font-medium mb-1">Ticket ID</label>
                            <input
                                type="text"
                                value={selectedTicket.ticket_id}
                                disabled
                                className="w-full bg-gray-100 border border-gray-200 rounded py-2 px-3 cursor-not-allowed"
                            />
                        </div>

                        {/* Order ID */}
                        <div>
                            <label className="block text-gray-700 font-medium mb-1">Order ID</label>
                            <input
                                type="text"
                                value={selectedTicket.reference_id}
                                disabled
                                className="w-full bg-gray-100 border border-gray-200 rounded py-2 px-3 cursor-not-allowed"
                            />
                        </div>

                        {/* Tipo de Ticket */}
                        <div>
                            <label className="block text-gray-700 font-medium mb-1">Tipo de Ticket</label>
                            <input
                                type="text"
                                value={selectedTicket.event_ticket_name}
                                disabled
                                className="w-full bg-gray-100 border border-gray-200 rounded py-2 px-3 cursor-not-allowed"
                            />
                        </div>

                        {/* Nombre */}
                        <div>
                            <label className="block text-gray-700 font-medium mb-1">Nombre</label>
                            <input
                                type="text"
                                value={selectedTicket.ticket_name}
                                onChange={(e) => handleTicketFieldChange('ticket_name', e.target.value)}
                                className="w-full border border-gray-300 rounded py-2 px-3 focus:outline-none focus:border-blue-500"
                            />
                        </div>

                        {/* DNI */}
                        <div>
                            <label className="block text-gray-700 font-medium mb-1">DNI</label>
                            <input
                                type="text"
                                value={selectedTicket.ticket_dni}
                                onChange={(e) => handleTicketFieldChange('ticket_dni', e.target.value)}
                                className="w-full border border-gray-300 rounded py-2 px-3 focus:outline-none focus:border-blue-500"
                            />
                        </div>

                        {/* Email */}
                        <div>
                            <label className="block text-gray-700 font-medium mb-1">Email</label>
                            <input
                                type="text"
                                value={selectedTicket.ticket_email}
                                onChange={(e) => handleTicketFieldChange('ticket_email', e.target.value)}
                                className="w-full border border-gray-300 rounded py-2 px-3 focus:outline-none focus:border-blue-500"
                            />
                        </div>
                    </div>

                    <h2 className="text-xl font-semibold mt-6 mb-3">Otra Información</h2>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        {/* Fecha de Evento */}
                        <div>
                            <label className="block text-gray-700 font-medium mb-1">
                                Fecha de Evento (GMT+0)
                            </label>
                            <input
                                type="text"
                                value={selectedTicket.event_date}
                                disabled
                                className="w-full bg-gray-100 border border-gray-200 rounded py-2 px-3 cursor-not-allowed"
                            />
                        </div>

                        {/* Fin del Evento */}
                        <div>
                            <label className="block text-gray-700 font-medium mb-1">
                                Fin Evento (GMT+0)
                            </label>
                            <input
                                type="text"
                                value={selectedTicket.event_date_end}
                                disabled
                                className="w-full bg-gray-100 border border-gray-200 rounded py-2 px-3 cursor-not-allowed"
                            />
                        </div>

                        {/* Event ID */}
                        <div>
                            <label className="block text-gray-700 font-medium mb-1">Event ID</label>
                            <input
                                type="text"
                                value={selectedTicket.event_id}
                                disabled
                                className="w-full bg-gray-100 border border-gray-200 rounded py-2 px-3 cursor-not-allowed"
                            />
                        </div>

                        {/* Nombre del Evento */}
                        <div>
                            <label className="block text-gray-700 font-medium mb-1">Nombre del Evento</label>
                            <input
                                type="text"
                                value={selectedTicket.event_name}
                                disabled
                                className="w-full bg-gray-100 border border-gray-200 rounded py-2 px-3 cursor-not-allowed"
                            />
                        </div>
                    </div>

                    {/* Botón para Modificar */}
                    <div className="mt-6">
                        <button
                            type="submit"
                            disabled={isSubmitting}
                            className={`px-6 py-2 text-white font-semibold rounded
                ${isSubmitting ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700'}
              `}
                        >
                            {isSubmitting ? 'Guardando...' : 'Modificar Ticket'}
                        </button>
                    </div>
                </form>
            )}
        </div>
    );
};

export default TicketEditor;
