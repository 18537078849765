import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

const EventDetails = (props) => {
    const [event, setEvent] = useState({});
    const [disabledButton, setDisabledButton] = useState(false);

    const token = localStorage.getItem('token');
    const navigate = useNavigate();

    const fetchData = async () => {
        try {
            const request = await fetch(
                `https://internal-api.flashpass.com.ar/event-approve?event_id=${props.id}`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        credentials: 'same-origin',
                        Authorization: 'Bearer ' + token,
                    },
                }
            );
            const response = await request.json();
            if (response.status === 'success') {
                setEvent(response.data);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const approveEvent = async () => {
        try {
            setDisabledButton(true);
            const request = await fetch(
                'https://internal-api.flashpass.com.ar/event-approve',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        credentials: 'same-origin',
                        Authorization: 'Bearer ' + token,
                    },
                    body: JSON.stringify({
                        event: props.id,
                    }),
                }
            );
            const response = await request.json();
            if (response.status === 'success') {
                navigate('/event-list');
            }
        } catch (error) {
            console.error(error);
        } finally {
            setDisabledButton(false);
        }
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="max-w-5xl mx-auto p-4">
            {/* "Card" de portada y detalles */}
            <div className="bg-white shadow-md rounded overflow-hidden flex flex-col md:flex-row">
                {/* Imagen del evento */}
                <img
                    src={event.portada}
                    alt="Event Cover"
                    className="w-full md:w-72 h-auto object-cover"
                />

                {/* Contenido del evento */}
                <div className="flex-1 p-4">
                    <h2 className="text-2xl font-bold mb-3">{event.event_name}</h2>

                    <p className="mb-2">
                        <strong>Descripción:</strong> {event.description}
                    </p>
                    <p className="mb-2">
                        <strong>Fecha inicio:</strong> {event.event_date}
                    </p>
                    <p className="mb-2">
                        <strong>Fecha fin:</strong> {event.event_date_end}
                    </p>
                    <p className="mb-2">
                        <strong>Link:</strong>{' '}
                        <a
                            href={`https://flashpass.com.ar/events/${event.event_url}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-600 hover:underline break-all"
                        >
                            {`https://flashpass.com.ar/events/${event.event_url}`}
                        </a>
                    </p>
                    <p className="mb-2">
                        <strong>Producer Name:</strong> {event.producer_name}
                    </p>
                    <p>
                        <strong>Producer ID:</strong> {event.producer_id}
                    </p>
                </div>
            </div>

            {/* Sección de Tickets */}
            <div className="mt-6">
                <h3 className="text-xl font-semibold mb-2">Tickets</h3>
                <div className="overflow-x-auto bg-white shadow-md rounded">
                    <table className="w-full text-left border-collapse">
                        <thead className="border-b">
                        <tr>
                            <th className="py-3 px-4 font-semibold">Nombre</th>
                            <th className="py-3 px-4 font-semibold">Descripción</th>
                            <th className="py-3 px-4 font-semibold">Precio</th>
                            <th className="py-3 px-4 font-semibold">Stock</th>
                        </tr>
                        </thead>
                        <tbody>
                        {event.event_tickets && event.event_tickets.length > 0 ? (
                            event.event_tickets.map((ticket, index) => (
                                <tr
                                    key={index}
                                    className="border-b hover:bg-gray-50 transition-colors"
                                >
                                    <td className="py-3 px-4">{ticket.event_ticket_name}</td>
                                    <td className="py-3 px-4">{ticket.event_ticket_description}</td>
                                    <td className="py-3 px-4">$ {ticket.event_ticket_value}</td>
                                    <td className="py-3 px-4">{ticket.event_ticket_limit}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td className="py-3 px-4" colSpan={4}>
                                    No tickets available for this event.
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Botones de acción */}
            <div className="mt-6 flex gap-4">
                <button
                    onClick={approveEvent}
                    disabled={disabledButton}
                    className={`px-4 py-2 text-white font-semibold rounded
            ${disabledButton
                        ? 'bg-green-400 cursor-not-allowed'
                        : 'bg-green-600 hover:bg-green-700'}
          `}
                >
                    Aprobar Evento
                </button>

                <button className="px-4 py-2 text-white font-semibold rounded bg-red-600 hover:bg-red-700">
                    Rechazar Evento
                </button>
            </div>
        </div>
    );
};

export default EventDetails;
