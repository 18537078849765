import React, { useEffect } from 'react';
import {
    BrowserRouter,
    Routes,
    Route,
    useParams,
    useNavigate, Navigate
} from 'react-router-dom';
import { Provider, useDispatch } from 'react-redux';
import store from './redux/configureStore';

import Home from './components/Home';
import EventPayment from './components/EventPayment';
import Expenses from './components/Expenses';
import ProofOfTicket from './components/ProofOfTicket';
import RetiroSalaEmergente from './components/RetiroSalaEmergente';
import Login from './components/Login';
import ExpensesDetails from './components/ExpensesDetails';
import BalanceSheet from './components/BalanceSheet';
import ProfitAndLoss from './components/ProfitAndLoss';
import EventList from './components/EventList';
import EventDetails from './components/EventDetails';
import ModifyTicket from './components/ModifyTicket';
import ProducerAuth from './components/ProducerAuth';

import { login } from './redux/userSlice';

// Componentes para manejar parámetros de la URL
const OrderId = () => {
    const { id } = useParams();
    return <ProofOfTicket id={id} />;
};

const EventId = () => {
    const { id } = useParams();
    return <EventDetails id={id} />;
};

// Componente que maneja la lógica de validación del token
function AppWrapper() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // Función para validar el token contra tu API
    const validateToken = async (token) => {
        try {
            const response = await fetch('https://internal-api.flashpass.com.ar/check-jwt.php', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    credentials: 'same-origin',
                    Authorization: `Bearer ${token}`,
                },
            });

            let json = null;
            try {
                // Intentamos obtener la respuesta como JSON
                json = await response.json();
            } catch (error) {
                // Si la respuesta no es JSON o hay otro problema, manejamos el error abajo
            }

            // Si la respuesta no fue OK, o el status es 'error' => token no válido
            if (!response.ok || json?.status === 'error' || response.status === 401) {
                // Eliminamos el token y redirigimos a login
                window.localStorage.removeItem('token');
                navigate('/login');
            } else {
                // Si es válido, actualizamos el store con el token
                dispatch(login({ token }));
            }
        } catch (error) {
            // Error de red u otro tipo de error
            window.localStorage.removeItem('token');
            navigate('/login');
        }
    };

    // Efecto que corre al montar el componente
    useEffect(() => {
        const storedToken = window.localStorage.getItem('token');
        if (!storedToken) {
            // Si no hay token, forzamos el login
            navigate('/login');
        } else {
            // Validamos el token contra la API
            validateToken(storedToken);
        }
    }, [dispatch, navigate]);

    return (
        <Routes>
            {/* Rutas "públicas" o de acceso sin token */}
            <Route path="/login" element={<Login />} />

            {/* Rutas que requieren token válido */}
            <Route path="/" element={<Home />} />
            <Route path="/event-payment" element={<EventPayment />} />
            <Route path="/retiro-sala-emergente" element={<RetiroSalaEmergente />} />
            <Route path="/transactions/new" element={<Expenses />} />
            <Route path="/transactions" element={<ExpensesDetails />} />
            <Route path="/financial-statement" element={<BalanceSheet />} />
            <Route path="/profit-and-loss-statement" element={<ProfitAndLoss />} />
            <Route path="/event-list" element={<EventList />} />
            <Route path="/event/:id" element={<EventId />} />
            <Route path="/order/:id" element={<OrderId />} />
            <Route path="/modify-ticket" element={<ModifyTicket />} />
            <Route path="/producer-auth" element={<ProducerAuth />} />
            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    );
}

// App principal con Provider y BrowserRouter
function App() {
    return (
        <Provider store={store}>
            <div className="App min-h-screen bg-white-100">
                <BrowserRouter>
                    <AppWrapper />
                </BrowserRouter>
            </div>
        </Provider>
    );
}

export default App;
