import React, { useEffect, useState } from 'react';
import {
    Button,
    Container,
    FormControl,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    TextField
} from "@mui/material";
import { selectUser } from "../redux/userSlice";
import { useSelector } from "react-redux";

import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css'; // Import opcional si quieres estilos

const RetiroSalaEmergente = () => {
    const user = useSelector(selectUser);

    const [values, setValues] = useState({
        event: '',
        paymentType: '',
        amount: 0,
        bankCBU: '',
        bankCUIT: '',
        bankName: '',
        fiscalType: '',
    });

    const [events, setEvents] = useState([]);

    const [disabledConfirm, setDisabledConfirm] = useState(false);

    // Si sigues usando Snackbars, puedes mantenerlos, de lo contrario se pueden remover
    const [openS, setOpenS] = useState(false);
    const [openE, setOpenE] = useState(false);

    const handleCloseS = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenS(false);
    };

    const handleCloseE = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenE(false);
    };

    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        });
    };

    const fetchEvents = async () => {
        const response = await fetch('https://internal-api.flashpass.com.ar/events.php', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'same-origin',
                'Authorization': 'Bearer ' + user.token,
            }
        });
        const json = await response.json();
        setEvents(json.data);
    };

    const submitData = async () => {
        // Mostramos SweetAlert con loader mientras se ejecuta la petición
        Swal.fire({
            title: 'Cargando...',
            text: 'Estamos procesando tu solicitud',
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });

        setDisabledConfirm(true);

        try {
            const request = await fetch('https://internal-api.flashpass.com.ar/retiro-sala-emergente.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'credentials': 'same-origin',
                    'Authorization': 'Bearer ' + user.token,
                },
                body: JSON.stringify(values),
            });

            const response = await request.json();

            // Cerramos el loader
            Swal.close();

            if (response.communicationMsg === "Success") {
                // Alerta de éxito
                Swal.fire({
                    icon: 'success',
                    title: 'Pago realizado con éxito',
                    text: 'Se ha registrado el pago correctamente',
                    confirmButtonText: 'OK'
                });
                setSuccessMessage('Pago realizado con éxito');
                setOpenS(true); // Muestra el Snackbar de éxito si lo deseas
            } else {
                // Alerta de error
                Swal.fire({
                    icon: 'error',
                    title: 'Error al realizar el pago',
                    text: 'Hubo un problema al registrar el pago',
                    confirmButtonText: 'OK'
                });
                setErrorMessage('Error al realizar el pago');
                setOpenE(true); // Muestra el Snackbar de error si lo deseas
            }
        } catch (error) {
            // Cierra el loader por si ocurre algún error al hacer fetch
            Swal.close();
            // Alerta de error
            Swal.fire({
                icon: 'error',
                title: 'Error inesperado',
                text: 'No se pudo procesar tu solicitud',
                confirmButtonText: 'OK'
            });
            setErrorMessage('Error al realizar el pago');
            setOpenE(true);
        } finally {
            setDisabledConfirm(false);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // Aquí podrías sanitizar/validar los datos antes de enviarlos
        submitData();
    };

    useEffect(() => {
        fetchEvents();
    }, []);

    return (
        <div>
            <Container>
                <div className="row">
                    <div className="col-12">
                        <h1 className="text-2xl font-bold mb-6">Retiros EL EMERGENTE</h1>
                    </div>
                </div>

                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-lg-6">
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Evento</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={values.event}
                                    label="Evento"
                                    onChange={handleChange}
                                    name={'event'}
                                >
                                    {events.map((event) => (
                                        <MenuItem
                                            key={event.event_id}
                                            value={event.event_id}
                                        >
                                            {event.event_id} - {event.event_name} - {event.event_date}
                                        </MenuItem>
                                    ))}
                                    {events.length === 0 && (
                                        <MenuItem disabled value={0}>
                                            No hay eventos disponibles
                                        </MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-lg-6">
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Tipo de pago</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={values.paymentType}
                                    label="Tipo de pago"
                                    onChange={handleChange}
                                    name={'paymentType'}
                                >
                                    <MenuItem value={"Adelanto"}>Adelanto</MenuItem>
                                    <MenuItem value={"Liquidacion Final"}>Liquidacion Final</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-lg-6">
                            <FormControl fullWidth>
                                <InputLabel htmlFor="outlined-adornment-amount">Monto</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-amount"
                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                    label="Monto"
                                    onChange={handleChange}
                                    name={'amount'}
                                    type={"number"}
                                    value={values.amount}
                                />
                            </FormControl>
                        </div>
                    </div>

                    <div className="row mt-5">
                        <div className="col-lg-6">
                            <TextField
                                label="CBU"
                                value={values.bankCBU}
                                onChange={handleChange}
                                className="mb-3"
                                type="number"
                                fullWidth
                                variant="outlined"
                                name={'bankCBU'}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-6">
                            <TextField
                                label="CUIT"
                                value={values.bankCUIT}
                                onChange={handleChange}
                                className="mb-3"
                                type="number"
                                fullWidth
                                variant="outlined"
                                name={'bankCUIT'}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-6">
                            <TextField
                                label="Nombre del Beneficiario"
                                value={values.bankName}
                                onChange={handleChange}
                                className="mb-3"
                                type="text"
                                fullWidth
                                variant="outlined"
                                name={'bankName'}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-6">
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Condición Fiscal</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={values.fiscalType}
                                    label="Condición Fiscal"
                                    onChange={handleChange}
                                    name={'fiscalType'}
                                >
                                    <MenuItem value={4}>Consumidor Final</MenuItem>
                                    <MenuItem value={5}>Responsable Monotributo</MenuItem>
                                    <MenuItem value={1}>Responsable Inscripto</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>

                    <div className="row mt-4">
                        <div className="col-lg-6">
                            <Button
                                variant="contained"
                                color="primary"
                                type={"submit"}
                                fullWidth
                                disabled={disabledConfirm}
                            >
                                Emitir Comprobante
                            </Button>
                        </div>
                    </div>
                </form>
            </Container>
        </div>
    );
};

export default RetiroSalaEmergente;
