import React, { useEffect, useState } from 'react';
import { selectUser } from "../redux/userSlice";
import { useSelector } from "react-redux";
import {
    Container,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    Typography,
    TextField,
    Grid,
    IconButton
} from "@mui/material";
import { BiDownload } from "react-icons/bi";

// tu number_format
import { number_format } from "./functions/numberFormat";

const ExpensesDetails = () => {
    const user = useSelector(selectUser);

    const [expenses, setExpenses] = useState([]);
    const [filteredExpenses, setFilteredExpenses] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState(''); // ej: "2024-12"

    const fetchData = async () => {
        const request = await fetch('https://internal-api.flashpass.com.ar/expenses.php', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'same-origin',
                'Authorization': 'Bearer ' + user.token,
            }
        });
        const response = await request.json();
        setExpenses(response.data);
        setFilteredExpenses(response.data);
    };

    // Filtramos sin usar new Date:
    useEffect(() => {
        if (!selectedMonth) {
            setFilteredExpenses(expenses);
            return;
        }

        // split: "YYYY-MM"
        const [filterYear, filterMonth] = selectedMonth.split('-');

        const filtered = expenses.filter(expense => {
            // expense.date = "2025-01-01"
            const [yyyy, mm] = expense.date.split('-');
            return (
                parseInt(yyyy, 10) === parseInt(filterYear, 10) &&
                parseInt(mm, 10) === parseInt(filterMonth, 10)
            );
        });
        setFilteredExpenses(filtered);

    }, [selectedMonth, expenses]);

    useEffect(() => {
        fetchData();
    }, [user.token]);

    const handleMonthChange = (event) => {
        setSelectedMonth(event.target.value); // ej: "2024-12"
    };

    return (
        <Container sx={{ mt: 4 }}>
            <Typography variant="h4" gutterBottom>
                Transactions
            </Typography>

            <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
                <Grid item xs={12} sm={4} md={3}>
                    <TextField
                        fullWidth
                        label="Filtrar por mes"
                        type="month"
                        value={selectedMonth}
                        onChange={handleMonthChange}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
            </Grid>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead sx={{ backgroundColor: '#f5f5f5' }}>
                        <TableRow>
                            <TableCell><strong>Fecha</strong></TableCell>
                            <TableCell><strong>Descripción</strong></TableCell>
                            <TableCell><strong>Categoría</strong></TableCell>
                            <TableCell><strong>Monto</strong></TableCell>
                            <TableCell><strong>Descargar</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredExpenses.map((expense, index) => (
                            <TableRow key={index}>
                                <TableCell>{expense.date}</TableCell>
                                <TableCell>{expense.description}</TableCell>
                                <TableCell>{expense.category}</TableCell>
                                <TableCell>
                                    {number_format(expense.amount, 2, ',', '.')}
                                </TableCell>
                                <TableCell>
                                    {expense.downloadUrl ? (
                                        <IconButton
                                            component="a"
                                            href={expense.downloadUrl}
                                            target="_blank"
                                            rel="noreferrer"
                                            aria-label="Descargar"
                                        >
                                            <BiDownload />
                                        </IconButton>
                                    ) : (
                                        <Typography variant="body2" color="textSecondary">
                                            No disponible
                                        </Typography>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                        {filteredExpenses.length === 0 && (
                            <TableRow>
                                <TableCell colSpan={5} align="center">
                                    No se encontraron transacciones para el mes seleccionado.
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
};

export default ExpensesDetails;
